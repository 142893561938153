// Font
$font_poppins_bold: "Poppins-Bold";
$font_poppins_medium: "Poppins-Medium";
$font_calling_code: "Calling-Code";

// Colors
$color_background_header: #a8dcdd;
$color_background_body: #ffffff;
$color_action_button: #0f383f;
$color_row_even: #f7f8f8;
$color_row_odd: $color_row_even;
$color_dialog_border: #0f383f;
$color_background_pagination: #f7f8f8;
$color_input_field: $color_row_even;
$color_background_input: $color_row_even;
