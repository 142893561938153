// Import Poppins-Bold
@font-face {
    font-family: "Poppins-Bold";
    src: url("../assets/fonts/poppins/Poppins-Bold.ttf") format("truetype");
}

// Import Poppins-Medium
@font-face {
    font-family: "Poppins-Medium";
    src: url("../assets/fonts/poppins/Poppins-Medium.ttf") format("truetype");
}

// Import Calling-Code
@font-face {
    font-family: "Calling-Code";
    src: url("../assets/fonts/calling-code/CallingCode-Regular.ttf") format("truetype");
}
