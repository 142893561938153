/* You can add global styles to this file, and also import other style files */

// Import FontAwesome Css
@import "assets/site/fontawesome/css/all.css";

// Import Bootstrap

// Import Fonts
@import "styles/fonts";

// Import Variables
@import "styles/variables";

// Import Mixins
@import "styles/mixins";

// Angular Material Default Theme
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

// Setup Global Styles
html {
    margin: 0;
    body {
        font-family: $font_calling_code !important;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: $font_poppins_bold !important;
        }
    }
}

// Setup scroll bar styles
// /* width */
::-webkit-scrollbar {
    width: 10px;
}

// /* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

// /* Handle */
::-webkit-scrollbar-thumb {
    background: $color_action_button;
    border-radius: 10px;
}

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//     background: #574b9c;
// }

// Setup style for dialogs
.custom-dialog .mat-dialog-container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border: 4px solid $color_dialog_border;
    border-radius: 10px;
}
